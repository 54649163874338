<template>
  <div class="text-center">

    <h3>{{ $t('login.reset_password.title') }}</h3>

    <!-- Loading -->
    <div class="text-center" v-if="is_loading">
      <img src="assets/loading.gif">
      <p>{{ $t('login.reset_password.loading') }}</p>
    </div>

    <!-- Expired seed -->
    <aside class="alert danger" v-if="expired_seed && !is_loading">
      <i class="fa fa-times-circle"></i>
      {{ $t('login.reset_password.alert_expired') }} <a href="/#/?resetPassword=true">{{ $t('login.reset_password.click_here') }}</a>.
    </aside>

    <!-- Content -->
    <div v-if="!expired_seed && !is_loading">
      <!-- Form -->
      <div v-if="!success">
        <p>{{ $t('login.reset_password.new_password_title') }}</p>
        <div class="password-icon">
          <input :type="hide_password ? 'password' : 'text'" v-model="password" :placeholder="$t('login.reset_password.placeholder_password')">
          <i v-if="hide_password" @click="hide_password = false" class="fas fa-eye-slash fa-lg"></i>
          <i v-else @click="hide_password = true" class="far fa-eye fa-lg"></i>
        </div>
        <div class="password-icon">
          <input :type="hide_password ? 'password' : 'text'" v-model="password_confirmation" :placeholder="$t('login.reset_password.placeholder_password_confirmation')">
          <i v-if="hide_password" @click="hide_password = false" class="fas fa-eye-slash fa-lg"></i>
          <i v-else @click="hide_password = true" class="far fa-eye fa-lg"></i>
        </div>
        <button @click="updateUser" class="primary">
          {{ $t('login.reset_password.reset_btn') }}
        </button>
        <!-- Errors -->
        <aside class="alert danger" v-if="errors.length">
          <i class="fa fa-exclamation-circle"></i>
          <span :key="error" v-for="error in errors">{{ error }}</span>
        </aside>
      </div>
      <!-- Success -->
      <aside class="alert success" v-if="success">
        <i class="fa fa-check-circle"></i>
        {{ $t('login.reset_password.alert_success_msg') }}
        <a href="/">{{ $t('login.reset_password.alert_success_btn') }}</a>.
      </aside>
    </div>

  </div>
</template>

<script setup>

  import {ref, inject} from 'vue';
  import {useRoute} from 'vue-router';
  import {useI18n} from 'vue-i18n';

  const {t} = useI18n();
  const route = useRoute();

  const sty = inject('sentry');
  const axios = inject('axios');

  const is_loading = ref(false);
  const password = ref('');
  const password_confirmation = ref('');
  const hide_password = ref(true);
  const errors = ref([]);
  const expired_seed = ref(false);
  const success = ref(false);

  const updateUser = () => {
    validatePassword();

    if (!errors.value.length) {
      is_loading.value = true;

      axios.put(`${process.env.API_URL}/account/password/reset`, {password: password.value, seed: route.query.seed})
        .then(() => {
          is_loading.value = false;
          success.value = true;
        })
        .catch((error) => {
          is_loading.value = false;
          if (error.status === 498) {
            expired_seed.value = true;
          } else {
            errors.value = [t('login.error_processing')];
            sty.captureException(error);
          }
        });
    }
  };

  const validatePassword = () => {
    errors.value = [];

    if (!password.value || !password_confirmation.value) {
      errors.value.push(t('login.reset_password.error_password_empty'));
    }
    if (password.value !== password_confirmation.value) {
      errors.value.push(t('login.reset_password.error_password_identical'));
    }
    if ((password.value.length < 8)) {
      errors.value.push(t('login.reset_password.error_password_characters'));
    }
  };


  if (route.query.seed) {
    let timestamps = Number(route.query.seed.substring(16)) * 1000;
    let date = new Date(timestamps);

    date.setDate(date.getDate() + 1);
    if (Date.now() > date) {
      expired_seed.value = true;
    }
  }
</script>
